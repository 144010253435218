var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('router-link',{attrs:{"to":{name: 'ordinativo_checklist', params: {id: _vm.$route.params.id}}}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" Dettaglio ")])],1),_c('Confirm',{attrs:{"message":"Aggiungi allegato","width":"400px"},on:{"confirm":_vm.save},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'ispettore']))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Valuta ")]):_vm._e()]}},{key:"default",fn:function(){return [_c('v-text-field',{attrs:{"label":"Punteggio","type":"number"},model:{value:(_vm.instance.punteggio),callback:function ($$v) {_vm.$set(_vm.instance, "punteggio", $$v)},expression:"instance.punteggio"}}),_c('v-textarea',{attrs:{"label":"Motivazione"},model:{value:(_vm.instance.motivazione),callback:function ($$v) {_vm.$set(_vm.instance, "motivazione", $$v)},expression:"instance.motivazione"}}),_c('v-textarea',{attrs:{"label":"Proposte"},model:{value:(_vm.instance.proposte),callback:function ($$v) {_vm.$set(_vm.instance, "proposte", $$v)},expression:"instance.proposte"}}),_c('v-textarea',{attrs:{"label":"Criticità"},model:{value:(_vm.instance.criticita),callback:function ($$v) {_vm.$set(_vm.instance, "criticita", $$v)},expression:"instance.criticita"}}),_c('v-textarea',{attrs:{"label":"Note"},model:{value:(_vm.instance.note),callback:function ($$v) {_vm.$set(_vm.instance, "note", $$v)},expression:"instance.note"}})]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":[
            {value:'punteggio', text: 'Punteggio'},
            {value:'motivazione', text: 'Punteggio'},
            {value:'proposte', text: 'Punteggio'},
            {value:'criticita', text: 'Punteggio'},
            {value:'actions', align: 'right'}],"items":_vm.valutazioni},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('Confirm',{attrs:{"message":"Confermi la cancellazione del file?","width":"450px","item":item},on:{"confirm":_vm.cancella},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'ispettore']))?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" Cancella ")]):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }