<template>
    <v-card>
        <v-card-title>
            <router-link
                :to="{name: 'ordinativo_checklist', params: {id: $route.params.id}}">
                <v-btn color="primary" class="mr-2">
                    Dettaglio
                </v-btn>
            </router-link>
            <Confirm message="Aggiungi allegato" width="400px" @confirm="save">
                <template #activator="{on, attrs}">
                    <v-btn v-if="$auth(['amministratore', 'ispettore'])" color="success" v-bind="attrs" v-on="on"
                           class="ml-auto">
                        Valuta
                    </v-btn>
                </template>
                <template #default>
                    <v-text-field v-model="instance.punteggio"
                                  label="Punteggio" type="number"></v-text-field>
                    <v-textarea v-model="instance.motivazione"
                                label="Motivazione"/>
                    <v-textarea v-model="instance.proposte"
                                label="Proposte"/>
                    <v-textarea v-model="instance.criticita"
                                label="Criticità"/>
                    <v-textarea v-model="instance.note"
                                label="Note"/>

                </template>
            </Confirm>
        </v-card-title>
        <v-data-table
            :headers="[
                {value:'punteggio', text: 'Punteggio'},
                {value:'motivazione', text: 'Punteggio'},
                {value:'proposte', text: 'Punteggio'},
                {value:'criticita', text: 'Punteggio'},
                {value:'actions', align: 'right'}]"
            :items="valutazioni"
        >
            <template #item.actions="{item}">
                <Confirm message="Confermi la cancellazione del file?" width="450px" :item="item"
                         @confirm="cancella">
                    <template #activator="{on, attrs}">
                        <v-btn v-if="$auth(['amministratore', 'ispettore'])" small color="error" v-bind="attrs"
                               v-on="on">
                            Cancella
                        </v-btn>
                    </template>
                </Confirm>
            </template>
        </v-data-table>
    </v-card>
</template>


<script>
import Confirm from "@/components/Confirm";

export default {
    components: {
        Confirm,
    },
    data: () => ({
        ordinativo: {},
        instance: {},
        valutazioni: []
    }),
    mounted() {
        this.loadValutazione();
        this.instance.ordinativo = this.$route.params.id;
    },
    methods: {

        async loadValutazione() {
            const res = await this.$http.get(`checklist/compilazione-valutazione/`, {ordinativo: this.$route.params.id});
            if (res.ok) {
                this.valutazioni = res.result.data.results;
            }
        },
        async save(dialog) {
            const res = await this.$http.post(`checklist/compilazione-valutazione/`, this.instance);
            if (res.ok) {
                await this.loadValutazione();
            }
            this.instance = null;
            dialog.value = false;
        },
        async cancella(item) {
            await this.$http.delete(`checklist/compilazione-valutazione/${item.id}/`);
            await this.loadValutazione();
        },

    }
}
</script>